import React, { useEffect, useState } from 'react';
import Divertissement from './Categorys';
import MyComponent from './Herosection';
import LatestPosts from './LatestPosts';
import Posts from './Posts';
import Enterprice from "./Enterprice";
import axios from "axios";
import { Helmet } from 'react-helmet';

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initially check if it's a mobile screen
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios
      .get(`https://roloeganga.net/admin/api/meta`)
      .then((response) => {
        if (response.data) {
          console.log(response.data)
          setNewsDetail(response.data);
        } else {
          console.log(response.data)
          setNewsDetail(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
        setError("Failed to fetch news details.");
      });
  }, []); // Empty dependency array ensures the effect runs only once
    
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Set padding conditionally based on the screen size
  const paddingStyle = isMobile ? '35px 25px' : '30px 100px';

  return (
    <>
    <Helmet>
                <title>{newsDetail ? newsDetail.meta_title : "RoloeGanga"}</title>
                <meta name="title" content={newsDetail ? newsDetail.meta_title : "RoloeGanga"} />
            <meta name="description" content={newsDetail ? newsDetail.meta_description : ""} />
            </Helmet>
    <div style={{ padding: paddingStyle, fontFamily: 'Sen, sans-serif', paddingTop: '70px' }}>
      <MyComponent />
      <Divertissement />
      <Posts />
      <Enterprice />
      <LatestPosts />
    </div></>
  );
}

export default Home;
