import React, { useEffect } from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set a timeout to navigate to the root after 1 second
    const timer = setTimeout(() => {
      navigate('/');
    }, 1000);

    // Clean up the timer if the component is unmounted before the timeout
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>404 - Página no encontrada</title>
      </Helmet>
      <div style={{ padding: '160px 100px', fontFamily: 'Sen, sans-serif', display: 'flex', flexGrow: '1' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={12}>
            <Card sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h3" style={{ fontWeight: 'bold', marginBottom: '15px' }}>
                  404 - Página no encontrada
                </Typography>
                <Typography variant="h6" color="textSecondary" style={{ marginBottom: '30px' }}>
                  Lo sentimos, la página que estás buscando no existe o ha sido movida.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NotFoundPage;
