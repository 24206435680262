import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Card, CardMedia, CardContent, Typography, Divider, Avatar, Button, Grid, List, ListItem } from '@mui/material';
import { removeHtmlTags } from './utils';
import { Link } from 'react-router-dom';
import NotFoundPage from './NotFound';
import { Helmet } from 'react-helmet';

const BASE_URL = "https://roloeganga.net";

function PostDetails() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [latestNews, setLatestNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tocHeadings, setTocHeadings] = useState([]);
    const [showToc, setShowToc] = useState(false);
    const [activeTocItem, setActiveTocItem] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Fetching post details and latest news
    useEffect(() => {
        const fetchPostDetails = async () => {
            try {
                const postResponse = await axios.get(`${BASE_URL}/admin/api/news/details/${slug}`);
                const fetchedPost = postResponse.data.news;

                setPost(fetchedPost);

                // Extract and process headings
                extractHeadings(fetchedPost.description);

                const latestNewsResponse = await axios.get(`${BASE_URL}/admin/api/latest/news`);
                setLatestNews(latestNewsResponse.data.news);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data');
                setIsLoading(false);
            }
        };

        if (slug) {
            fetchPostDetails();
        }
    }, [slug]);

    useEffect(() => {
        const onScroll = () => {
            const headings = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
            let activeId = null;
            headings.forEach((heading) => {
                const rect = heading.getBoundingClientRect();
                if (rect.top <= 0 && rect.bottom >= 0) {
                    activeId = heading.id;
                }
            });
            setActiveTocItem(activeId);
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const formatDate = (date) => {
        const formattedDate = new Date(date);
        if (!isNaN(formattedDate.getTime())) {
            return formattedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
        return 'Invalid Date';
    };

    const extractHeadings = (description) => {
        const headings = [];
        const regex = /<(h[1-6])>(.*?)<\/\1>/g;
        let match;
        let index = 1;
        let modifiedDescription = description;

        while ((match = regex.exec(description)) !== null) {
            const tag = match[1];
            const text = match[2];
            const id = `heading-${index}`;
            headings.push({ id, text });

            modifiedDescription = modifiedDescription.replace(
                match[0],
                `<${tag} id="${id}" class="heading-class">${text}</${tag}>`
            );
            index++;
        }

        setTocHeadings(headings);
        setPost((prevPost) => ({
            ...prevPost,
            description: modifiedDescription,
        }));
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 100, // Adjust offset for sticky headers
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (post) {
            const jsonLd = {
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                headline: post.title,
                description: post.meta_description || "No description available.",
                datePublished: post.date,
                author: {
                    "@type": "Person",
                    name: post.writerName || "Unknown",
                },
                image: post.image || "https://via.placeholder.com/1200x600",
                url: window.location.href,
            };
            const script = document.createElement("script");
            script.type = "application/ld+json";
            script.innerHTML = JSON.stringify(jsonLd);
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [post]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <NotFoundPage/>
        );
    }

    if (!post) {
        return (
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6" color="textSecondary">
                    Publicación no encontrada.
                </Typography>
            </div>
        );
    }

    const paddingStyle = isMobile ? '10px' : '40px 100px';

    return (
        <>
        <Helmet>
            <title>{post.title || "RoloeGanga"}</title>
            <meta name="title" content={post ? post.meta_title : "Joaquin Sabina"} />
        <meta name="description" content={post ? post.meta_description : ""} />
        </Helmet>
        <div style={{ padding: paddingStyle, fontFamily: 'Sen, sans-serif' }}>
            <Grid container spacing={3} marginTop={"40px"} marginBottom={"40px"}>
                <Grid item xs={12} md={8}>
                    <Card sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                        <CardContent style={{ padding: '20px' }}>
                            <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '15px' }} id="post-title">
                                {post.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    alt={removeHtmlTags(post.writerName || 'Unknown')}
                                    src={post.writerImage || '/default-profile.png'}
                                    sx={{ width: 30, height: 30, marginRight: '10px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
                                />
                                <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                                <a style={{color:"#000", textDecoration:"none"}} href={`/autor/${post.writerName}`}>{post.writerName}</a>
                                </span>
                                <span>{formatDate(post.date || post._id)}</span>
                            </Typography>
                            <CardMedia
                                component="img"
                                image={post.image || '/default-image.jpg'}
                                alt={post.title}
                                style={{
                                    width: '100%',
                                    height: 'auto', // Ensures the height adjusts based on the aspect ratio
                                    maxHeight: '400px', // Optional: limits the maximum height
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    marginTop: '20px',
                                }}
                            />

                            <Divider style={{ margin: '20px 0' }} />

                            <Button
                                variant="contained"
                                style={{
                                    marginBottom: '10px',
                                    fontSize: '12px',
                                    textAlign: 'left',
                                    padding: '7px 15px 7px 10px',
                                    backgroundColor: "#33c4ff",
                                    color: 'white',
                                    width: 'fit-content',
                                    height: '32px',
                                    clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)',
                                    transition: 'all 0.3s ease-in-out',
                                    display: 'inline-block',
                                    borderRadius: '0',
                                }}
                                onClick={() => setShowToc(!showToc)}
                            >
                                {'Table des matières'}
                            </Button>

                            {showToc && (
                                <div style={{
                                    position: 'sticky',
                                    top: 80,  // Keep ToC visible even when scrolling
                                    maxHeight: '80vh',
                                    overflowY: 'auto',
                                    padding: '20px',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    backgroundColor: '#f9f9f9',
                                }}>
                                    <List>
                                        {tocHeadings.map((heading, index) => (
                                            <ListItem key={index} style={{ padding: '5px 0' }}>
                                                <a
                                                    to={`#${heading.id}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        scrollToSection(heading.id);
                                                    }}
                                                    style={{
                                                        color: activeTocItem === heading.id ? '#fff' : '#33c4ff', // Default color or active color
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.3,
                                                        textDecoration: 'none',
                                                        display: 'block',
                                                        padding: '10px 15px',
                                                        fontSize: '16px',
                                                        backgroundColor: activeTocItem === heading.id ? '#FF6F00' : 'transparent',
                                                        borderRadius: '4px',
                                                        transition: 'all 0.3s ease', // Smooth transition for all properties
                                                        cursor: 'pointer'
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = '#33c4ff'; // On hover, background turns black
                                                        e.target.style.color = '#fff'; // Change color to white on hover
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = activeTocItem === heading.id ? '#FF6F00' : 'transparent';
                                                        e.target.style.color = activeTocItem === heading.id ? '#fff' : '#33c4ff';
                                                    }}
                                                >
                                                    {`${index + 1}. ${heading.text}`}
                                                </a>

                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            )}

                            <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: post.description || 'No description available.' }} />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Latest News Sidebar */}
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '15px' }}>Últimas Noticias</Typography>
                    {latestNews.length === 0 ? (
                        <Typography variant="body2" color="textSecondary">No latest news available.</Typography>
                    ) : (
                        latestNews.map((newsItem, index) => (
                            <Card key={index} sx={{ display: 'flex', alignItems: 'center', padding: '10px', borderRadius: '8px', backgroundColor: '#fff', width: '95%', marginBottom: '15px' }}>
                                <Link to={`/${newsItem.slug}`} style={{ textDecoration: 'none', width: '120px' }}>
                                    <CardMedia
                                        component="img"
                                        image={newsItem.image || '/default-image.jpg'}
                                        alt={newsItem.title}
                                        style={{
                                            width: '120px',
                                            height: '100px',
                                            objectFit: 'cover',
                                            borderRadius: '8px',
                                            marginRight: '20px',
                                        }}
                                    />
                                </Link>
                                <CardContent style={{ marginLeft: "20px", display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0' }}>
                                    <Link to={`/${newsItem.slug}`} style={{ textDecoration: 'none', width: '100%' }}>
                                        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '14px', color: '#333', marginBottom: '6px' }}>
                                            {newsItem.title}
                                        </Typography>
                                    </Link>
                                    <Typography variant="body2" color="textSecondary" style={{ fontSize: '10px', color: '#888', lineHeight: '1.4' }}>
                                        <span>{formatDate(newsItem.date || newsItem._id)}</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}
                </Grid>
            </Grid>
        </div>
        </>
    );
}

export default PostDetails;
