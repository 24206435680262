import './App.css';
import Home from './components/homePage/Home';
import SearchAppBar from './components/header/Appbar';
import { Route, BrowserRouter, Routes, useLocation } from 'react-router-dom';
import Footer from './components/homePage/Footer';
import SinglePage from './components/singlepage/SignlePage';
import PostDetails from './helper/PostDetail';
import PrivacyPolicy from './components/homePage/PrivacyPolicy';
import ContactUs from './components/homePage/ContactUs';
import AboutUs  from './components/homePage/AboutUs';
import SearchResults from './components/homePage/SearchComponent';
import { useEffect } from 'react';
import AllNewsPage from './components/singlepage/AllNewsPage';
import WriterPage from './components/singlepage/WriterPage';
import NotFoundPage from './helper/NotFound';
// Component to reset scroll position on route change
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    // Reset the scroll position to the top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return null; // This component doesn't render anything
}

function App() {
  return (
    <BrowserRouter>
      <div className="container" style={{minHeight:"100vh", display:"flex", flexDirection:"column", height:"100%", justifyContent:"center", alignItems:"center"}}>
        <SearchAppBar />
        
        {/* Scroll to top on route change */}
        <ScrollToTop />
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/categoría/:category" element={<SinglePage />} /> 
          <Route path="/autor/:writer" element={<WriterPage />} /> 
          <Route path="/:slug" element={<PostDetails/>} />
          <Route path="/noticias" element={<AllNewsPage/>}/>
          <Route path="/sobre-nosotros" element={<AboutUs />} /> {/* About Us page */}
          <Route path="/contáctanos" element={<ContactUs />} /> {/* Contact Us page */}
          <Route path="/política-de-privacidad" element={<PrivacyPolicy />} />
          <Route path="/buscar" element={<SearchResults/>} />
          <Route path='*' element={<NotFoundPage/>}/>
        </Routes>
        
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
