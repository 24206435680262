import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Avatar,
  Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const AllNewsPage = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 16;
  const [totalPages, setTotalPages] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const buttonColors = [
    "#FF5733",
    "#33C4FF",
    "#28A745",
    "#FF914D",
    "#F9A825",
    "#9C27B0",
  ];

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://roloeganga.net/admin/api/all/news_page?page=${currentPage}&limit=${postsPerPage}`
        );
        const { news, pagination } = response.data;

        setPosts(news || []);
        setTotalPages(pagination?.totalPages || 1);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching posts:", err);
        setError("Failed to load posts");
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [currentPage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const limitTitle = (title) => (title.length > 35 ? `${title.slice(0, 35)}...` : title);

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return isNaN(formattedDate.getTime())
      ? "Invalid Date"
      : formattedDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: for smooth scrolling
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h6" color="textSecondary">
          No hay publicaciones disponibles. Vuelve a consultar más tarde.
        </Typography>
      </div>
    );
  }

  const paddingStyle = isMobile ? "30px" : "30px 100px";

  return (
    <>
    <Helmet>
              <title>{"Todas las Publicaciones | RoloeGanga"}</title>
            </Helmet>
    <div style={{ padding: paddingStyle, fontFamily: "Sen, sans-serif" }}>
      <Typography
        variant="h5"
        style={{ fontWeight: "bold", marginBottom: "20px", marginTop: "60px" }}
      >
        Todas las Publicaciones
      </Typography>

      <Grid container spacing={3} justifyContent="left" p="10px 0px">
        {posts.map((post, index) => (
          <Grid item xs={12} sm={6} md={3} key={post._id}>
            <Card
              sx={{
                boxShadow: "none",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link to={`/${post.slug}`} style={{ textDecoration: "none" }}>
                <CardMedia
                  component="img"
                  image={post.image || "/default-image.jpg"}
                  alt={post.title}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
              </Link>
              <CardContent style={{ padding: "16px" }}>
  <Button
    style={{
      marginBottom: "10px",
      fontSize: "12px",
      backgroundColor: buttonColors[index % buttonColors.length],
      color: "#fff",
      clipPath: "polygon(0 0, 100% 0, 90% 100%, 0 100%)",
      display: "block", // Ensures it takes its own line
    }}
  >
    {post.category}
  </Button>
  <div style={{ marginBottom: "10px" }}>
    <Typography
      variant="h7"
      style={{
        fontWeight: "bold",
        display: "block", // Ensures title is on its own line
      }}
    >
      {limitTitle(post.title)}
    </Typography>
  </div>
  <Typography variant="body2" color="textSecondary" style={{ display: "flex", alignItems: "center" }}>
    <Avatar
      alt={post.writerName || "Unknown"}
      src={post.writerImage || "/default-profile.png"}
      sx={{ width: 30, height: 30, marginRight: "10px" }}
    />
    <a style={{color:"#000", textDecoration:"none"}} href={`/autor/${post.writerName}`}>{post.writerName}</a> - {formatDate(post.date)}
  </Typography>
</CardContent>

            </Card>
          </Grid>
        ))}
      </Grid>

      <div style={{ margin: "30px", display: "flex", justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          aria-label="pagination"
        />
      </div>
    </div>
    </>
  );
};

export default AllNewsPage;
