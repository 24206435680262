import React from 'react';
import { Container, Typography, Box, Paper, Divider, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <>
    <Helmet>
      <title>Contáctanos | RoloeGanga</title>
    </Helmet>
    <Container maxWidth="lg" style={{ fontFamily: 'Inter, sans-serif', fontStyle: 'normal', padding: '90px 50px' }} sx={{ flexGrow: 1, }}>
      <Paper elevation={5} style={{ padding: '30px', borderRadius: '12px', backgroundColor: '#FFFFF' }}>
        <Typography variant="h4" gutterBottom align="center" style={{ fontWeight: 'bold', color: '#333' }}>
          Contáctanos
        </Typography>

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            ¿Cómo podemos ayudarte?
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Si tienes sugerencias o preguntas, no dudes en contactarnos usando los datos que se encuentran a continuación.
            Estaremos encantados de saber de ti.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Información de contacto
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Para cualquier pregunta o sugerencia, puedes contactarnos a través del siguiente correo electrónico:
          </Typography>
          <Box display="flex" alignItems="center" style={{ color: '#555' }}>
            <EmailIcon style={{ color: '#3498db', marginRight: '8px' }} />
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              <Link href="mailto:contact@roloeganga.net" style={{ color: '#3498db', textDecoration: 'none', fontWeight: '550' }}>
                contact@roloeganga.net
              </Link>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
    </>
  );
};

export default ContactUs;
